/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;1,300;1,400&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap");

html,
body {
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Cormorant Garamond', serif; */
  scroll-behavior: smooth;
}

a:hover {
  cursor: pointer;
}

/*Suspense fallback={<LoadingIndicator />}*/
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(200, 235, 184);
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* @media (orientation: landscape) {
  body {
    display: none;
  }
} */

/*edno resenie.... koa kje se svrti tel niso ne pokazuva..*/

/*Scrolling Indicator css*/
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  opacity: 0.8;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.icon-scroll {
  width: 25px;
  height: 40px;
  /* margin-left: -20px; */
  /* top: 50%; */
  /* margin-top: -35px; */
  box-shadow: inset 0 0 0 1px #7a7d7d;
  border-radius: 25px;
}

.icon-scroll:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #7a7d7d;
  margin-left: 9px;
  top: 6px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  0% {
    opacity: 1;
    transform: translateY(20px);
  }
  100% {
    opacity: 0;
  }
}

/* ImagesCarousel CSS */
.slider-image {
  object-fit: cover;
  width: 100%;
  height: 600px;
}
@media (max-width: 2000px) {
  .slider-image {
    height: 850px;
  }
}
@media (max-width: 1900px) {
  .slider-image {
    height: 800px;
  }
}
@media (max-width: 1800px) {
  .slider-image {
    height: 750px;
  }
}
@media (max-width: 1700px) {
  .slider-image {
    height: 700px;
  }
}
@media (max-width: 1600px) {
  .slider-image {
    height: 650px;
  }
}
@media (max-width: 1500px) {
  .slider-image {
    height: 600px;
  }
}
@media (max-width: 1400px) {
  .slider-image {
    height: 550px;
  }
}
@media (max-width: 1300px) {
  .slider-image {
    height: 500px;
  }
}
@media (max-width: 1200px) {
  .slider-image {
    height: 450px;
  }
}
@media (max-width: 1100px) {
  .slider-image {
    height: 400px;
  }
}
@media (max-width: 1000px) {
  .slider-image {
    height: 500px;
  }
}

@media (max-width: 900px) {
  .slider-image {
    height: 450px;
  }
}
@media (max-width: 800px) {
  .slider-image {
    height: 400px;
  }
}
@media (max-width: 700px) {
  .slider-image {
    height: 350px;
  }
}
@media (max-width: 600px) {
  .slider-image {
    height: 300px;
  }
}
@media (max-width: 500px) {
  .slider-image {
    height: 250px;
  }
}
@media (max-width: 400px) {
  .slider-image {
    height: 200px;
  }
}
@media (max-width: 300px) {
  .slider-image {
    height: 150px;
  }
}

/* .image-slider {
  display: flex;
  justify-content: center;
} */
/*----------------------------*/
/*DARK MODE*/
.dark-mode:not(.inverted) {
  filter: invert(1) hue-rotate(180deg);
}

.invert {
  filter: invert(1) hue-rotate(180deg);
}
/* */

/*SELECTED ITEMS IN THE MENU SO IT HIGHLIGHTS THEM*/
.highlight {
  /* font-weight: bold; */
  /* font-size: 1.1em;*/
}

.link {
  position: relative;
}

.link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #9cde47;
  /* transition: width 0.3s; */
}

.link.highlight::before {
  width: 100%;
  animation: underline 0.3s ease-out;
}

@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
/**/

/*SCROLL ANIMATION EFFECT*/
.slide.show {
  transform: translateY(0);
  opacity: 1;
}

/*A TAG ANIMATION STYLE*/
#smooth-scroll {
  transition: all 0.5s ease-in-out;
}

@keyframes mymove {
  from {
    top: 0;
  }
  to {
    top: 100;
  }
}
.active {
  animation: mymove 0.5s ease-in-out;
}

/*Custom class to protect images from being saved*/
.customprotectclass {
  position: relative;
  display: inline-block;
}

.customprotectclass img {
  vertical-align: bottom;
}

.customprotectclass:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

#dental-photography {
  margin-bottom: 40px;
}
@media (max-width: 1000px) {
  #dental-photography {
    margin-bottom: 150px;
  }
}
